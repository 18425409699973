$use_maison: true;
/* stylelint-disable selector-pseudo-class-no-unknown */
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.flyout-cart {
  max-height: 100%;
  outline: none;

  @include breakpoint(desktop) {
    position: absolute;
    width: 477px;
  }

  a {
    color: $black;
  }
}

.flyout-cart__progress {
  @include z-index('modal');

  background: $white;
  padding: $spacer-s $spacer-s 0;
  position: sticky;
  top: 0;

  @include breakpoint(desktop) {
    height: auto;
    padding: $spacer-sm $spacer-sm $spacer-m;
  }
}

.flyout-cart__scrollable-sections {
  @include z-index('banner');

  flex: 1 0 auto;
  padding: 0 $spacer-s 56px;

  @include breakpoint(desktop) {
    padding: 0 $spacer-sm $spacer-m;
  }
}

.flyout-cart__cart {
  @include breakpoint(desktop) {
    margin-bottom: $spacer-m;
  }

  :global {
    .everlane-loading-spinner {
      margin: 100px auto;
    }
  }
}

.flyout-cart__cart-header {
  @include font($heading-medium-bold);
}

.flyout-cart__cart-error-banner {
  @include font($body-small);

  font-size: 12px !important;
  line-height: calc(16 / 10 * 1rem) !important;
  margin-top: $spacer-sm;

  &--bolded {
    @include font($body-small-bold);
  }
}

.flyout-cart__line-items-container {
  padding-bottom: $spacer-m;
  position: relative;
  top: $spacer-sm;

  @include breakpoint(desktop) {
    padding-bottom: 0;
    padding-top: $spacer-s;
    top: 0;
  }
}

.flyout-cart__line-items {
  margin: 0;
}

.flyout-cart__upsells-header,
.flyout-cart__subtotal-header {
  @include font($heading-small-bold);
}

.flyout-cart__upsells-header {
  padding: $spacer-sm 0 $spacer-xs;
}

.flyout-cart__subtotal-header {
  padding: $spacer-s $spacer-sm 0;

  &--unbolded {
    @include font($body-medium);
  }
}

.flyout-cart__subtotal-header-amount {
  float: right;
}

.flyout-cart__subtotal {
  @include z-index('navbar');

  background: $white;
  bottom: 0;
  box-shadow: 0 -6px 18px -8px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  height: auto;
  padding: 0;
  position: sticky;
}

.flyout-cart__express {
  padding: 0;
  width: 100%;

  button {
    margin: 0;
    min-height: 56px;

    @include breakpoint(desktop) {
      min-height: 50px;

      :global {
        .paypal-logo {
          left: 7px;
          position: relative;
          top: 0;

          image {
            width: 70px;
          }
        }
      }
    }

    :global {
      .paypal-logo {
        left: 7px;
        position: relative;
        top: 2px;
      }
    }
  }
}

.flyout-cart__subtotal-discount {
  @include font($body-small-bold);

  background: rgba(151, 177, 122, 0.2);
  color: $green-500;
  left: -8px;
  padding: $spacer-xs;
  position: relative;
  text-align: center;
  width: 100%;
}

button.flyout-cart__subtotal-continue-button {
  @include breakpoint(desktop) {
    margin-bottom: 0;
  }
}

.flyout-cart__subtotal-returns-messaging {
  @include font($body-small);

  padding: $spacer-xs 0;
  text-align: center;

  b {
    @include font($body-small-bold);
  }
}

.flyout-cart__duties-copy {
  @include font($body-small);
}

.flyout-cart__submessage-copy {
  padding: 0 $spacer-sm $spacer-s $spacer-sm;

  @include breakpoint(desktop) {
    padding: 0 $spacer-sm $spacer-sm;
  }
}

.flyout-cart__subtotal-returns-copy {
  @include font($body-small);

  padding: $spacer-s 0;
  text-align: center;

  &--bolded {
    @include font($body-small-bold);
  }
}

.flyout-cart__applepay-button {
  background: $white;
  border: 1px solid $black;
  height: 56px;

  @include breakpoint(desktop) {
    margin-top: $spacer-xs;
  }
}

.flyout-cart__digital-wallet-wrapper {
  width: 0 !important;

  @include breakpoint(desktop) {
    margin: $spacer-s 0;
  }

  &--visible {
    width: 100% !important;
  }
}

div.flyout-cart__button-container {
  display: flex;
  padding: 0 $spacer-sm;

  @include breakpoint(desktop) {
    flex-wrap: wrap;

    > * {
      width: 100%;
    }
  }

  &--digital-wallet {
    flex-direction: row;

    @include breakpoint(mobile) {
      & button + * {
        margin-left: $spacer-xs;
      }
    }

    :global {
      .GooglePayButton-logo {
        height: 52px;
      }
    }
  }
}

.empty-cart__out-of-stock-wrapper {
  height: 100%;
  margin-left: -8px;
  overflow: hidden;
  width: calc(100% + 16px);
}

.empty-cart__out-of-stock-copy {
  @include font($heading-medium);

  padding: 96px $spacer-s 0;

  @include breakpoint(desktop) {
    @include font($heading-medium);

    padding: 96px $spacer-s;
  }

  p {
    margin-bottom: 24px;

    @include breakpoint(desktop) {
      @include font($heading-medium);
    }
  }

  a {
    @include font($heading-medium-bold);

    text-decoration: none;
  }
}

.empty-cart__out-of-stock-image-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.urgency-messaging {
  margin-bottom: $spacer-s;
}
