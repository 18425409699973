$use_maison: true;
/* stylelint-disable no-descending-specificity */

@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.desktop-nav__container {
  background: $white;
  border-bottom: 1px solid $grey-300;
  display: flex;
  justify-content: space-around;

  .desktop-nav__categories {
    display: inline-flex;

    .desktop-nav__category {
      @include font($body-small);

      white-space: nowrap;
    }

    .desktop-nav__category--active a {
      color: $black !important;
    }

    .desktop-nav__category-link {
      color: $grey-600;
      display: block;
      padding: 20px $spacer-s;
      padding-left: min(1vw, #{$spacer-s});
      padding-right: min(1vw, #{$spacer-s});
      text-decoration: none;
    }

    &:hover {
      .desktop-nav__category-link {
        color: $grey-400;

        &:hover {
          color: $black;
        }
      }
    }
  }
}

.category-dropdown__veil {
  background-color: $black;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 56px;
  visibility: hidden;
  width: 100vw;
  z-index: -1;

  &,
  &[hidden] {
    display: flex;
  }

  &:not([hidden]) {
    opacity: 0.5;
    transition: opacity 300ms ease-out 200ms, visibility 0ms;
    visibility: visible;
  }

  &[hidden] {
    opacity: 0;
    transition: opacity 200ms ease-out 300ms, visibility 0ms ease 350ms;
    visibility: hidden;
  }
}
