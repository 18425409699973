$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.product-recommendation {
  & > a {
    text-decoration: none;
  }

  &__overlay-button {
    padding: 0;
    text-align: left;
  }
}

.product-recommendation__title {
  @include font($body-small);

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-recommendation__details {
  margin-top: $spacer-xxs;
}

.product-recommendation__details-content {
  color: $black;
}

.product-recommendation__price {
  @include font($body-small-bold);
}

.product-recommendation__original-price {
  color: $grey-400;
  margin-right: $spacer-xxs;
  text-decoration: line-through;
}
