$use_maison: true;
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/mixins';
@import 'styles/spacers';

// builder-block z-index is 100000
$builder-block-z-index-plus-one: 100001;

.flyout {
  @include z-index('modal');

  background: $white;
  transform: translateX(100%);
  transition: transform 420ms ease-in-out;

  &.after-open {
    transform: translateX(0);
  }

  &.before-close {
    transform: translateX(100%);
  }
}

.flyout__close-button {
  cursor: pointer;
  justify-content: right;
  left: calc(100% - #{$spacer-ml});
  margin-bottom: -24px;
  position: sticky;
  top: $spacer-xs;
  z-index: $builder-block-z-index-plus-one;
}

.flyout__body {
  max-height: 100vh;
}
