$use_maison: true;
@import 'styles/spacers';
@import 'styles/mixins';

.easter-egg-content {
  padding-bottom: $spacer-sm;

  // All the following is a hacky CSS to handle EasterEggs heights in the grid
  @include breakpoint(mobile) {
    > div > div {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;

          > div {
            height: 100%;
          }
        }
      }
    }
  }

  a {
    display: inline !important;
    margin-top: 0 !important;
  }

  > div:nth-of-type(1) {
    height: calc(100% - 45px);

    @include breakpoint(desktop) {
      height: unset;
    }
  }

  div[builder-type~='blocks'] {
    display: block;
    height: 100%;
    overflow: hidden;
    
    @include breakpoint(desktop) {
      aspect-ratio: 0.662; /* stylelint-disable-line number-max-precision */
    }

    @include breakpoint(large-desktop) {
      aspect-ratio: 0.686; /* stylelint-disable-line number-max-precision */
      max-height: 640px;
      width: 100%;
    }
  }
}
