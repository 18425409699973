$use_maison: true;
@import 'styles/colors';

.progress-bar {
  background-color: $grey-200;
  border-radius: 100px;
  display: block;
  height: 10px;

  &--green {
    background: $green-200;
  }
}

.progress-bar--filled {
  background-color: $grey-400;
  border-radius: 100px;
  height: 10px;
  transition: width 1s;
}

.progress-bar--filled-green {
  background-color: $green-500;
}
