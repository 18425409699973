$use_maison: true;
/* stylelint-disable selector-pseudo-class-no-unknown */
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

$prod-rec-spacer: 20px;

.product-recommendations-carousel {
  @include font($body-xsmall);

  margin: $spacer-m calc(-1 * #{$prod-rec-spacer}) 0;
  position: relative;

  @include breakpoint(desktop) {
    margin: 0 calc(-1 * #{$prod-rec-spacer});
  }

  :global {
    .embla {
      margin-left: -$spacer-xxs;
      margin-right: -$spacer-xxs;
    }

    .embla__button--next svg {
      transform: scale(0.5);
    }

    .embla__button--prev svg {
      margin-right: 1px;
      transform: scale(-0.5) !important;
    }

    .arrow-svg {
      stroke-width: 3px;
    }

    .embla__slide {
      flex: 0 0 25%;
      margin-right: $spacer-xs;
      min-width: unset !important;

      @include breakpoint(desktop) {
        flex: 0 0 122px;
      }

      &:first-of-type {
        padding-left: $prod-rec-spacer;
      }

      &:last-of-type {
        padding-right: $prod-rec-spacer;
      }
    }
  }
}

.product-recommendations-carousel__title {
  @include font($body-medium-bold);

  margin-bottom: $spacer-xs;
  margin-left: $prod-rec-spacer;
}
