$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';
@import 'styles/constants';

.email-waitlist {
  &__desktop-layout {
    display: flex;

    @include breakpoint(desktop) {
      max-height: 100vh;
      max-width: 1064px;
      overflow-y: hidden;
    }
  }

  &__gallery-container {
    box-sizing: border-box;
    flex: 1;
    max-width: 600px;
  }

  &__details-container {
    box-sizing: border-box;
    flex: 1;
    margin: 0;
    max-width: 464px;
    padding: 0 40px;
    text-align: center;


    @media (max-height: 865px){
      padding: 120px 40px;
    }

    @media (min-height: 866px){
      padding: 200px 40px;
    }

    @include breakpoint(tablet) {
      max-height: 100vh;
      overflow-y: scroll;

      // padding-bottom: 40px;
      position: relative;
    }

    &__title {
      @include font($display-medium);
    }

    &__subtitle {
      @include font($body-medium-bold);

      margin-top: $spacer-xs;
    }

    &__copy {
      @include font($body-medium);

      color: $grey-400;
      margin-top: 40px;


      @media (max-height: 960px){
        margin-bottom: 316px;
      }

      @media (max-height: 865px){
        margin-bottom: 236px;
      }

      @media (max-height: 800px){
        margin-bottom: 150px;
      }

      @media (max-height: 640px){
        margin-bottom: 80px;
      }

      @media (min-height: 961px){
        margin-bottom: 356px;
      }
    }

    &__sign-up-copy {
      @include font($body-small);

      color: $grey-400;
      margin: $spacer-s $spacer-m 0;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  /* stylelint-disable selector-pseudo-class-no-unknown */
    :global {
      .everlane-form__input-error-message {
        margin-bottom: $spacer-xs;
      }
    }
  }

  &__button {
    &--success {
      background-color: $green-500 !important;
      color: $white !important;
    }
  }

  &__error {
    color: $red-500;
    margin-bottom: $spacer-xs;
    text-align: center;
    width: 100%;
  }
}
