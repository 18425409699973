$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';
@import 'styles/constants';

.email-waitlist {
  &__copy {
    @include font($body-medium);

    color: $grey-400;
    margin: $spacer-s 36px;
    text-align: center;
  }

  &__sign-up {
    margin: 0 0 45px;
    padding: 0 $spacer-s;

    input {
      margin-top: 0;
    }

    &-copy {
      @include font($body-small);

      color: $grey-400;
      margin: $spacer-s $spacer-s 0;
      text-align: center;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    /* stylelint-disable selector-pseudo-class-no-unknown */
    :global {
      .everlane-form__input-error-message {
        margin-bottom: $spacer-xs;
        text-align: center;
      }
    }
  }

  &__button {
    &--success {
      background-color: $green-500 !important;
      color: $white !important;
    }
  }

  &__error {
    color: $red-500;
    margin-bottom: $spacer-xs;
    text-align: center;
    width: 100%;
  }
}
