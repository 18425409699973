$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.display-group {
  margin-bottom: $spacer-l;
  width: 100%;

  &__heading {
    padding-bottom: $spacer-xs;
  }

  &__heading-title {
    @include font($heading-small);
  }

  &__heading-subtitle {
    @include font($body-medium);
  }

  &__products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
  }

  &--2-products-per-row {
    .display-group__products > * {
      width: calc(50% - 20px);
    }
  }

  &--3-products-per-row {
    .display-group__products > * {
      width: calc(33.33% - 13.33px);
    }
  }

  // This helper enables anchor tagging via URL on DisplayGroups,
  // covering the offset for the sticky nav on desktop
  &__anchor-helper {
    display: block;
    position: relative;
    top: -168px; // navHeight (152px) + offset (16px)
    visibility: hidden;
  }

  &__easter-egg {
    min-height: 408px;
    overflow: hidden;
    position: relative;
    vertical-align: top;
  }

  &__easter-egg-content {
    height: 0;
    overflow: hidden;
    padding-bottom: 125%;
    position: relative;

    div[builder-type~='blocks'] {
      aspect-ratio: calc(4 / 5);
      display: block;
      height: 100%;
      overflow: hidden;
    }
  }

  &__ingrid--skeleton {
    margin: $spacer-m 0;
  }

  .display-group__heading {
    &-title {
      @include font($heading-small);
    }

    &-subtitle {
      @include font($body-medium);

      color: $grey-400;
    }
  }
}
