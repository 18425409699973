$use_maison: true;
/* stylelint-disable selector-pseudo-class-no-unknown */

@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

$navbar-height: 56px;

.navbar {
  &-container {
    @include z-index(navbar);

    background: $white;
    height: 48px;
    left: 0;
    max-width: 100%;
    position: sticky;
    top: 0;
    width: 100vw;

    @media (min-width: 1025px) {
      height: 112px;

      &--minimal {
        border-bottom: none;
        height: $navbar-height;
      }
    }

    &--sticky {
      filter: drop-shadow(0 1mm 1mm rgba(0, 0, 0, 0.1));
    }

    :global {
      .everlane-floating-box {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

        &__footer {
          margin-top: 20px;
        }
      }
    }

    &--sticky-banner {
      top: 40px;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    height: 48px;
    padding: 0 68px;
    position: relative;
    text-align: center;

    @include breakpoint(desktop) {
      border-bottom: 1px solid $grey-300;
      height: $navbar-height;
      padding: 0 68px;

      &--minimal {
        border-bottom: none;
      }
    }

    @media (max-width: 1024px) {
      padding: 0 28px;
    }

    @include breakpoint(mobile) {
      padding: 0 4px;
    }

    &--minimal {
      .navbar__menu-left {
        display: none;
      }
    }

    &-container {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      justify-content: center;
      margin: auto;
      position: relative;
      width: min(100vw, 1464px);
    }

    .navbar__menu-left {
      height: 100%;
      left: 0;
      min-width: 30px;
      position: absolute;
      text-align: left;
    }

    .navbar__menu-center {
      display: flex;
      justify-content: center;
      width: 100%;

      @media (max-width: 1024px) {
        padding-top: 0;

        &--minimal {
          padding-top: 6px;
          width: 100%;
        }
      }

      @include breakpoint(desktop) {
        width: 33.3%;

        &--minimal {
          width: 100%;
        }
      }
    }

    .navbar__menu-right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      min-width: 30px;
      position: absolute;
      right: 0;
      text-align: right;

      @media (max-width: 1024px) {
        right: 8px;
      }
    }
  }

  &__indicators {
    left: 40px;
    position: absolute;
    top: 12px;

    @media (max-width: 1024px) {
      left: 34px;
      top: 19px;
    }
  }

  &__user-notification-indicators {
    position: absolute;
    right: 51px;
    top: 0;
  }

  &__icon-button {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 0;
    text-align: left;
    width: 40px;
  }

  &__icon-wrapper {
    align-items: center;
    display: flex;
    height: 100%;

    svg,
    svg path {
      color: $grey-600;
    }
  }

  &__link {
    cursor: pointer;
    display: block;

    @include breakpoint(desktop) {
      padding: 0;
    }

    &-wrapper {
      display: block;
      height: 40px;
      margin-left: 4px;
      margin-right: 0;

      &--active {
        border-bottom: 2px solid $black;
      }

      &--logo {
        height: inherit;
        margin-right: 0;
      }

      &--cart {
        margin-right: 0;
      }
    }

    &--logo {
      @include breakpoint(desktop) {
        height: 14px;
      }
    }

    svg,
    svg path {
      color: $grey-600;
    }

    svg:not(.everlane-logo) {
      padding: 12px;

      @include breakpoint(desktop) {
        border-radius: 8px;
        padding: 12px;

        &:hover {
          background-color: $grey-100;
        }

        &:active {
          background-color: $grey-300;
        }
      }

      @include breakpoint(mobile) {
        padding: 8px;
      }
    }
  }

  &__categories {
    display: flex;
    height: 100%;
  }

  &__category {
    display: flex;
    height: 100%;
    margin-right: 4px;

    &--active {
      box-shadow: inset 0 -2px 0 $black;

      a {
        color: $black;
      }
    }
  }

  &__category-link {
    @include font($body-small);

    border-radius: 8px;
    color: $grey-600;
    display: block;
    margin: auto;
    padding: 12px;
    text-decoration: none;

    &:hover {
      background: $grey-100;
      color: $black;
    }

    &:active {
      background-color: $grey-300;
    }
  }
}

svg.everlane-logo path,
svg.ellipsis-icon {
  fill: $grey-600;
}

svg.ellipsis-icon {
  height: 18px;
  transform: rotate(90deg);

  path {
    stroke: transparent;
  }
}
