$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.navbar__link {
  cursor: pointer;
  display: block;

  @include breakpoint(desktop) {
    padding: 0;
  }

  svg,
  svg path {
    color: $grey-600;
  }

  svg {
    padding: 12px;

    @include breakpoint(desktop) {
      border-radius: 8px;
      padding: 12px;

      &:hover {
        background-color: $grey-100;
      }

      &:active {
        background-color: $grey-300;
      }
    }

    @include breakpoint(mobile) {
      padding: 8px;
    }
  }
}

.cart-icon__count {
  @include font($body-xsmall-bold);

  align-items: center;
  background-color: $black;
  border: 2px solid $white;
  border-radius: 100%;
  border-radius: 10px;
  color: $white;
  display: flex;
  height: 16px;
  justify-content: center;
  line-height: 1em;
  padding: 0 1px;
  position: absolute;
  right: -9px;
  top: -8px;
  width: 14px;

  @media (max-width: 1024px) {
    right: -5px;
    top: -1px;
  }

  @include breakpoint(desktop) {
    right: -5px;
    top: -1px;
  }

  &--large {
    border-radius: 25px;
    right: -20px;
    width: 25px;
  }
}
